html, body
	height: 100%
	width: 100%
	-webkit-font-smoothing: antialiased
	margin: 0
	padding: 0

*, *:before, *:after
	box-sizing: border-box

html
	// border: 10px solid $white
	background-color: #000


// ------------------------
// BASICS
// ------------------------

h2
	font-family: $regular
	font-size: 3.5rem
a
	color: $brown
	text-decoration: none

p
	font-size: 1.2rem
	line-height: 1.7

wbr
	display: none

span.bold
	font-weight: bold

.container
	width: 100%
	border-bottom: 0px solid $white
.innerWrap
	width: 70%
	max-width: 1024px
	margin: 0 auto
	height: auto
	// border: 1px solid $white

section, footer
	min-height: 700px
	height: auto
	border: 20px solid $white
	border-bottom: 0px solid transparent
	overflow-x: hidden
	overflow-y: visible
	max-width: 1600px
	margin: 0 auto
footer, section.news
	border-bottom: 20px solid $white
	

// ------------------------
// NAVIGATION
// ------------------------


.mobHam
	transition: top 0.1s ease-in-out 0s
	position: fixed
	display: none
	z-index: 90
	top: 20px
	right: 20px
	height: 100px
	width: 100px
	background: #000
	cursor: pointer
	&.up
		top: 0px
	span
		transition: background 0.1s ease-in-out 0s
		display: block
		height: 12px
		width: 64px
		margin-top: 10px
		background: $white
		margin-left: 19px
		&:first-child
			margin-top: 21px

.mobMenu
	transition: all 0.29s ease-in-out 0s
	position: fixed
	height: 100%
	width: 100%
	background-color: #000
	background: rgba(0,0,0,0.8)
	z-index: 999
	font-size: 78px
	opacity: 0
	transform: translateX(-100%)
	pointer-events: none
	&.active
		transform: translateX(0%)
		opacity: 1
		pointer-events: auto
	ul
		list-style: none
		padding: 64px 0 0 55px
		li
			margin-bottom: 4px
			a
				transition: color 0.1s ease-in-out 0s
				text-transform: uppercase
				font-weight: 800
				color: $white


	.mobMenu-exit
		transition: all 0.1s ease-in-out 0s
		position: absolute
		height: 64px
		width: 64px
		cursor: pointer
		top: 30px
		right: 7px
		padding: 0 67px 50px 50px
		&.up
			top: 10px
		&:hover
			opacity: 0.75
		&::after
			position: absolute
			transform: rotate(45deg)
			background: $white
			content: ''
			height: 78px
			width: 10px
		&::before
			position: absolute
			transform: rotate(-45deg)
			background: $white
			content: ''
			height: 78px
			width: 10px

header
	width: 100%
	position: absolute
	z-index: 9999
	&.mediaHeader
		nav
			ul.mainNav
				transform: matrix(1, 0, 0, 1, 0, 0)
			.navItem
				a
					color: $white
					&:hover
						color: $teal
			.ham
				span
					background: #413c34
	nav
		position: relative
		width: 80%
		.navMask
			float: right
			width: 536px
			overflow: hidden
		ul.mainNav
			text-align: right
			text-transform: uppercase
			font-family: $regular
			font-weight: bold
			margin-top: 40px
			font-size: 1.2rem
			width: 536px
			transform: matrix(1, 0, 0, 1, 510, 0)
		.navItem
			display: inline-block
			a
				transition: color 0.1s ease-in-out 0s
				margin: 0 10px
				letter-spacing: 0.5px
				color: $brown
				&:hover
					color: white !important
		.ham
			position: absolute
			height: 28px
			width: 28px
			top: 38px
			right: 10px
			padding-bottom: 4px
			cursor: pointer
			transform: matrix(1, 0, 0, 1, 56, 0)
			opacity: 0
			&:hover
				span
					background: $white !important 
			span
				transition: background 0.1s ease-in-out 0s
				display: block
				height: 4px
				width: 28px
				margin-top: 4px
				background: $brown

// ------------------------
// SECTIONS
// ------------------------

section.hero
	background-color: $teal
	position: relative
	.logo
		position: relative
		top: 255px
		z-index: 10
		left: 100px
	.heroImg
		position: absolute
		top: 130px
		right: -5%
		img
			transform: scale(0.96)
		img.animate
			animation: heroAnim 2s ease-out 0.7s
			transform: translateZ(0deg)
			animation-fill-mode: forwards
	@keyframes heroAnim 
		0% 
			transform: scale(0.96)
		
		100% 
			transform: scale(1)
		
	.arrowDown
		height: 25px
		width: 25px
		transform: rotate(135deg)
		position: relative
		top: 350px
		left: 115px
		border-top: 1px solid $brown
		border-right: 1px solid $brown
		border-bottom: 1px solid transparent
		border-left: 1px solid transparent
		cursor: pointer

section.galleryMobile
	display: none
section.gallery
	background-color: $white
	overflow: hidden
	height: $sliderHeight
	.innerWrap
		position: relative
		background-color: $teal
	.slideWrap
		position: absolute
		left: 50%
		width: 1240px
		height: $sliderHeight
		margin-left: -620px
	.arrow
		transition: all 0.1s ease-in-out 0s
		position: absolute
		top: 50%
		margin-top: -59px
		width: 102px
		height: 102px
		background-color: transparent
		border: 18px solid transparent
		border-top: 18px solid $brown 
		// background-blend-mode: multiply
		opacity: 0.7
		cursor: pointer
		&:hover
			opacity: 0.9
		&.aRight
			right: 158px
			transform: rotate(45deg)
			border-right: 18px solid $brown 
		&.aLeft
			left: 158px
			transform: rotate(-45deg)
			border-left: 18px solid $brown 
	.galleryText
		transition: all 0.6s ease-in-out 0s
		position: absolute
		width: 100%
		top: 306px
		text-align: center
		pointer-events: none
		opacity: 0
		&.shown
			opacity: 1
		h2
			display: inline-block
			font-family: $regular
			font-size: 51px
			letter-spacing: 1px
			color: $white
			padding: 6px 11px
			line-height: 52px 
			background-color: rgba(0,0,0,0.5)
			background-blend-mode: multiply
			span
				font-weight: 800
	ul.slideGroup
		position: relative
		width: 2000%
		height: auto
		list-style: none
		margin: 0
		padding: 0
		li
			position: absolute
			top: 0
			left: 0
			img
				width: 1240px
				height: $sliderHeight
				pointer-events: none

section.about
	background-color: $brown
	color: $white
	position: relative
	.aboutText
		position: relative
		top: 200px
		text-align: justify
		margin: 0 auto
		width: 90%
		padding-left: 40px
		&:before, &:after
			content: ""
			width: 1px
			height: 80%
			border-right: 2px solid lighten($brown, 10%)
			position: absolute
			left: 0px
		&:after
			right: 0px
			left: auto
			top: 0
		p 
			width: 95%
	.truckImg
		position: absolute
		top: 50px
		right: -10%
		transform: matrix(1, 0, 0, 1, 20, 0)

section.history
	position: relative
	background-color: #666
	text-align: center
	color: $white
	padding: 140px 0
	background-image: url(../../img/jpg/history.jpg)
	background-size: cover
	background-repeat: no-repeat
	
	.historyMobileWrapper
		display: none

	h2
		position: relative
		top: -10px
		display: inline-block
		padding: 0 10px
		transform: translateY(-25%)
		
	p
		text-align: left
		margin: 30px 0
		&:first-of-type
			margin-top: -20px
	.historyMain
		width: 85.5%
		// border-top: 1px solid $white
		border-bottom: 1px solid #888
		padding-bottom: 40px
		margin: 0 auto
		position: relative
		&:before, &:after
			content: ""
			width: calc(50% - 90px)
			height: 1px
			border-top: 1px solid lighten(#666, 40%)
			position: absolute
			left: 0%
			top: 10px
		&:after
			right: 0%
			left: auto
	.historyBtn
		transition: all 0.1s ease-in-out 0s
		color: $teal
		font-size: 2rem
		border: 1px solid $teal
		display: inline-block
		padding: 5px 10px
		margin-top: 20px
		cursor: pointer
		&:hover
			cursor: pointer
			background: rgba(255,255,255,0.1)
		h6
			font-size: 2rem
			font-weight: 600

.history-modal-wrapper
	transition: all 0.19s ease-in-out 0s
	position: absolute
	background-color: rgba(0,0,0,0.7)
	width: 100%
	height: 100%
	top: 0
	left: 0
	pointer-events: none
	opacity: 0
	&.active
		pointer-events: auto
		opacity: 1
	.history-modal
		position: absolute
		overflow: hidden
		top: 50%
		left: 50%
		width: 792px
		height: 446px
		margin-left: -396px
		margin-top: -223px
		background: $white
		p
			position: absolute
			left: 25px
			bottom: 13px
			margin: 0
			color: #5e554d
			font-weight: 800
			font-size: 16px
			text-transform: uppercase
			span.white
				color: #ffffff
			span.brown
				color: $brown
		.history-exit
			transition: all 0.1s ease-in-out 0s
			position: absolute
			top: 0
			right: 0
			font-family: $black
			color: #666a66
			background-color: #000000
			font-size: 20px
			line-height: 20px
			cursor: pointer
			padding: 4px 9px 6px 10px
			&:hover
				opacity: 0.9
		.history-paging
			transition: all 0.1s ease-in-out 0s
			position: absolute
			top: 50%
			margin-top: -14px
			font-family: $regular
			font-size: 25px
			color: $white
			background-color: #000000
			cursor: pointer
			text-transform: uppercase
			font-weight: 800
			line-height: 25px
			padding: 2px 4px 1px
			&:hover
				opacity: 0.8
			&.history-prev
				left: 25px
			&.history-next
				right: 25px
		ul.history-slider
			width: 3000%
			height: 446px
			list-style: none
			margin: 0
			padding: 0
			li
				float: left
				height: 446px
				width: 792px 
				overflow: hidden
				img
					height: 100%
					width: 100%
				span
					display: none !important

section.team
	background-color: $teal
	color: $white
	text-align: center
	h2
		background-color: $teal
		position: relative
		top: -20px
		display: inline-block
		padding: 0 10px
		transform: translateY(-25%)
	p
		color: $brown
		text-align: justify
		line-height: 2
		letter-spacing: 0px

	.teamMain
		width: 85.5%
		margin: 100px auto
		border-top: 1px solid $white
		border-bottom: 1px solid $white
		min-height: 470px
	.teamTexts
		position: relative
		padding-bottom: 20px
		.teamText
			position: relative
			top: 20px
			visibility: hidden
			&.active
				visibility: visible
			span.bold
				text-transform: uppercase
			a:hover, a:visited
				color: inherit

		.teamText2
			position: absolute
	.teamLogos, 
		width: 363px
		margin: 0 auto
		// border: 1px solid $brown
		.teamLogo
			background-size: contain !important
			background-repeat: no-repeat
			position: absolute
			opacity: 0
			transition: opacity 0.3s ease
			width: 50%
			&.active
				opacity: 1		
		.teamChoice, .teamWitt, 
			width: 50%
			float: left
			position: relative
			cursor: pointer
		.teamOutline
			float: left
		.teamChoice
			width: 155px
			height: 80px
			left: -20px
			&:hover
				.teamLogo1	
					opacity: 0
				.teamLogo1Dk
					opacity: 1
		.teamWitt
			width: 200px
			height: 50px
			// right: -10%
			right: -40px
			top: 5px
			&:hover
				.teamLogo2	
					opacity: 0
				.teamLogo2Dk
					opacity: 1
		.teamLogo1, .teamLogo1Dk
			background-image: url(../../img/svg/choiceLogo_lt.svg)
			width: 100%
			height: 100%
			.no-svg &
				background-image:url(../../img/png/choiceLogo_lt.png)
		.teamLogo2, .teamLogo2Dk
			background-image: url(../../img/svg/wittLogo_lt.svg)
			width: 100%
			height: 100%
			.no-svg &
				background-image:url(../../img/png/wittLogo_lt.png)
		.teamLogo1Dk
			background-image:  url(../../img/svg/choiceLogo_dk.svg)
			.no-svg &
				background-image:url(../../img/png/choiceLogo_dk.png)
		.teamLogo2Dk
			background-image: url(../../img/svg/wittLogo_dk.svg)
			.no-svg &
				background-image:url(../../img/png/wittLogo_dk.png)



section.news
	background-color: $brown
	text-align: center
	color: $white
	position: relative
	overflow-y: visible
	min-height: 600px
	.innerWrap
		position: relative
	.textarrows
		padding-bottom: 40px
		margin-top: -150px
	.textarrow 
		transition: color 0.1s ease-in-out 0s
		cursor: pointer
		font-family: $mod
		width: 50%
		float: left
		margin: -20px auto 0  
		text-align: center
		&.prev
			padding-left: 20%
		&.next
			padding-right: 20%
		p
			font-size: 3rem
			text-transform: uppercase
			color: rgba(0,0,0,0.3)
			transition: color 0.2s ease
			&:hover
				color: $white
	.sectional
		padding-bottom: 20px
	.articlesGroup
		position: relative
		width: 100%
		top: -100px
		min-height: 390px
	.articlesInner
		display: none
		height: 100%
		&.active
			display: block
	ul
		margin: 0
		padding: 0
	li
		list-style: none
	a
		color: $white
	.date
		position: relative
		display: inline-block
		.before, .after
			width: 40px
			height: 1px
			border-top: 1px solid white
			display: block
			position: absolute
			top: 50%
			right: 110%
		.after
			left: 110%
		h5
			font-size: 2rem
			letter-spacing: -1px

			display: inline-block

	.article
		line-height: 1.6
	.articleTitle
		text-transform: none
		font-size: 1.3rem
		letter-spacing: -0.1px
		.publication
			font-family: $regBlack
	h2
		line-height: 1
		pointer-events: none
		font-family: $mod
		font-size: 15rem
		opacity: 0.3
		color: black
		position: relative
		top: 200px


footer
	background-color: $brown
	min-height: 300px
	text-align: center
	color: $white
	border-top: 20px solid $white
	border-bottom: 20px solid $white
	p
		margin-bottom: 50px
	.choiceLink
		display: block
		width: 100% 
		height: 100%
	.teamLogos
		width: 363px
		margin: 0 auto
		.teamLogo
			width: 50%
			float: left
			background-size: contain !important
			background-repeat: no-repeat
		.teamLogo1
			background-image: url(../../img/svg/choiceLogo_lt.svg)
			width: 155px
			height: 80px
			.no-svg &
				background-image:url(../../img/png/choiceLogo_lt.png)
			.no-svg &
				background-image:url(../../img/png/wittLogo_lt.png)
		.teamLogo2
			background-image: url(../../img/svg/wittLogo_lt.svg)
			width: 200px
			height: 80px
			position: relative
			left: 50px
			.no-svg &
				background-image:url(../../img/png/choiceLogo_lt.png)
			.no-svg &
				background-image:url(../../img/png/wittLogo_lt.png)

// ------------------------
// CONTACT MODAL
// ------------------------

.contact-modal-wrap
	transition: all 0.19s ease-in-out 0s
	position: fixed
	background-color: transparent
	width: 100%
	height: 100%
	top: 0
	left: 0
	pointer-events: none
	opacity: 0
	z-index: 9999
	&.active
		pointer-events: auto
		opacity: 1
	.contact-modal
		position: absolute
		background-color: rgba(0,0,0,0.8)
		width: 440px
		height: 278px
		top: 50%
		left: 50%
		margin-top: -139px
		margin-left: -220px
		text-align: center
		padding: 40px
		p
			transition: opacity 0.1s ease-in-out 0s
			color: $teal
			font-size: 23px
			text-transform: uppercase
			line-height: 23px
			margin-top: 65px
			a
				margin-top: -55px
				display: block 
				text-transform: none
				font-size: 39px
				font-weight: 800
				color: $white
				&:hover
					color: $teal
				span
					font-family: $mod
		.contact-exit
			transition: opacity 0.1s ease-in-out 0s
			position: absolute
			height: 36px
			width: 36px
			cursor: pointer
			top: 5px
			right: 9px
			&:hover
				opacity: 0.75
			&::after
				position: absolute
				transform: rotate(45deg)
				background: $white
				content: ''
				height: 40px
				width: 6px
			&::before
				position: absolute
				transform: rotate(-45deg)
				background: $white
				content: ''
				height: 40px
				width: 6px


// ------------------------
// MEDIA LOGIN
// ------------------------

.media-modal-wrap
	transition: all 0.19s ease-in-out 0s
	position: fixed
	background-color: rgba(0,0,0,0.7)
	width: 100%
	height: 100%
	top: 0
	left: 0
	pointer-events: none
	opacity: 0
	z-index: 9999
	&.active
		pointer-events: auto
		opacity: 1
	.media-modal
		position: absolute
		background: $teal
		width: 800px
		height: 320px
		top: 50%
		left: 50%
		margin-top: -210px
		margin-left: -400px
		text-align: center
		padding: 40px
		h2
			color: #6a6057
			display: inline-block
			margin-bottom: 20px
			&:before, &:after
				content: ''
				width: 210px
				height: 1px
				border-top: 1px solid $brown
				display: block
				top: 80px
				left: 7.5%
				position: absolute
			&:after
				left: 66%
	form
		width: 95%
		margin: 0 auto
		display: block
		span.inEnclose
			display:block
			width: 48%
			text-align: left
			float: left
			&:nth-child(2)
				margin-left: 2px
				float: right
		label
			color: $white
			text-transform: uppercase
			font-size: 1.4rem
		input
			transition: all 0.1s ease-in-out 0s
			border: 0px solid transparent
			width: 100%
			height: 40px
			float: left
			padding: 0 15px
			&:focus
				outline: none
				box-shadow: 0 0 5px $brown;
				border: 1px solid $brown;
			&.submit
				width: 100px
				background-color: $brown
				color: $white
				font-family: $regular
				text-transform: uppercase
				font-size: 1.4rem
				float: right
				margin-top: 24px
				padding: 0
				&:hover
					color: $brown
					background-color: $white


// ------------------------
// MEDIA PAGE
// ------------------------

header.mediaHeader
	width: 100%
	max-width: 1600px
	left: 0
	nav
		width: 70%
		margin: 0 auto
		ul
			width: 100%
	right: 0
	a
		color: $white
	&:hover
		color: $teal

.mediaContainer
	border: 20px solid $white
	border-bottom: none
	max-width: 1600px
	margin: 0 auto
	width: 100%
	background-color: $brown
	color: $white
	padding-top: 120px
	text-transform: uppercase
	.innerWrap
		max-width: 800px
		padding-bottom: 50px

	h3
		font-family: $regular
		font-weight: 900
		font-size: 1.5rem
		letter-spacing: 1px	
		color: $teal
	.mediaTitle
		color: $white
		text-align: center
		position: relative
		.keyline
			position: absolute
			width: calc(50% - 80px)
			height: 1px
			display: block
			border-top: 1px solid $white
			top: 50%
			&:last-child
				right: 0
		h2
			display: inline-block
	.section
		border-bottom: 1px solid $teal
		margin-bottom: 25px
		padding-bottom: 10px
		&:last-child
			border-bottom: 2px solid lighten($brown, 20%)
		p
			margin: 15px 0
			line-height: 1.2
	.quartCont
		width: 100%
		margin: 10px 0 20px
	.quart
		width: calc(25% - 7.5px)
		float: left
		color: $white
		text-align: center
		margin-right: 10px
		&:nth-child(4n)
			margin-right: 0
		.download
			margin: 10px 0
			span
				font-weight: 100
			p
				margin: 0

				&.downloadLinks
					font-weight: bold
		.quartImg
			width: 100%
			height: 110px
			background-color: $white
			overflow: hidden
			img
				min-width: 193px
				min-height: 111px
				max-width: 200px
				max-height: 120px
	a
		color: $white



