@charset "UTF-8";
/*
 * HTML5 ✰ Boilerplate
 *
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 *
 * Detailed information about this CSS: h5bp.com/css
 *
 * ==|== normalize ==========================================================
 */
/* =============================================================================
 * HTML5 display definitions
 * ========================================================================== */
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]), [hidden] {
  display: none; }

/* =============================================================================
 * Base
 * ========================================================================== */
/*
 * 1. Correct text resizing oddly in IE6/7 when body font-size is set using em units
 * 2. Force vertical scrollbar in non-IE
 * 3. Prevent iOS text size adjust on device orientation change, without disabling user zoom: h5bp.com/g
 */
html {
  font-size: 100%;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

body {
  margin: 0;
  font-size: 13px;
  line-height: 1.231;
  font-family: sans-serif;
  color: #222; }

button, input, select, textarea {
  font-family: sans-serif;
  color: #222; }

/*
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection declarations have to be separate
 * Also: hot pink! (or customize the background color to match your design)
 */
::-moz-selection {
  background: #fe57a1;
  color: #fff;
  text-shadow: none; }

::selection {
  background: #fe57a1;
  color: #fff;
  text-shadow: none; }

/* =============================================================================
 * Links
 * ========================================================================== */
a {
  color: #00e; }
  a:visited {
    color: #551a8b; }
  a:hover {
    color: #06e; }
  a:focus {
    outline: thin dotted; }
  a:hover, a:active {
    outline: 0; }

/* =============================================================================
 * Typography
 * ========================================================================== */
abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

blockquote {
  margin: 1em 40px; }

dfn {
  font-style: italic; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

ins {
  background: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background: #ff0;
  color: #000;
  font-style: italic;
  font-weight: bold; }

pre, code, kbd, samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: none; }
  q:before, q:after {
    content: "";
    content: none; }

small {
  font-size: 85%; }

sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* =============================================================================
 * Lists
 * ========================================================================== */
ul, ol {
  margin: 1em 0;
  padding: 0 0 0 40px; }

dd {
  margin: 0 0 0 40px; }

nav ul, nav ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0; }

/* =============================================================================
 * Embedded content
 * ========================================================================== */
/*
 * 1. Improve image quality when scaled in IE7: h5bp.com/d
 * 2. Remove the gap between images and borders on image containers: h5bp.com/e
 */
img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle; }

/*
 * Correct overflow not hidden in IE9
 */
svg:not(:root) {
  overflow: hidden; }

/* =============================================================================
 * Figures
 * ========================================================================== */
figure {
  margin: 0; }

/* =============================================================================
 * Forms
 * ========================================================================== */
form {
  margin: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

label {
  cursor: pointer; }

/*
 * 1. Correct color not inheriting in IE6/7/8/9
 * 2. Correct alignment displayed oddly in IE6/7
 */
legend {
  border: 0;
  *margin-left: -7px;
  padding: 0; }

/*
 * 1. Correct font-size not inheriting in all browsers
 * 2. Remove margins in FF3/4 S5 Chrome
 * 3. Define consistent vertical alignment display in all browsers
 */
button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

/*
 * 1. Define line-height as normal to match FF3/4 (set using !important in the UA stylesheet)
 */
button, input {
  line-height: normal; }

/*
 * 1. Display hand cursor for clickable form elements
 * 2. Allow styling of clickable form elements in iOS
 * 3. Correct inner spacing displayed oddly in IE7 (doesn't effect IE6)
 * 4. Consistent box sizing and appearance
 */
button {
  cursor: pointer;
  -webkit-appearance: button;
  *overflow: visible; }

input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
  *overflow: visible; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }
  input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/*
 * Remove inner padding and border in FF3/4: h5bp.com/l
 */
button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/*
 * 1. Remove default vertical scrollbar in IE6/7/8/9
 * 2. Allow only vertical resizing
 */
textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

input:invalid, textarea:invalid {
  background-color: #f0dddd; }

/* =============================================================================
 * Tables
 * ========================================================================== */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td {
  vertical-align: top; }

/* ==|== primary styles =====================================================
 * Author:
 * ========================================================================== */
/* ==|== media queries ======================================================
 * PLACEHOLDER Media Queries for Responsive Design.
 * These override the primary ('mobile first') styles
 * Modify as content requires.
 * ========================================================================== */
/* ==|== non-semantic helper classes ========================================
 * Please define your styles before this section.
 * ========================================================================== */
.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr; }
  .ir br {
    display: none; }

.hidden {
  display: none !important;
  visibility: hidden; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }
  .visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto; }

.invisible {
  visibility: hidden; }

.clearfix {
  *zoom: 1; }
  .clearfix:before {
    content: "";
    display: table; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

/* ==|== print styles =======================================================
 * Print styles.
 * Inlined to avoid required HTTP connection: h5bp.com/r
 * ========================================================================== */
@media print {
  * {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important; }
  a {
    text-decoration: underline; }
    a:visited {
      text-decoration: underline; }
    a[href]:after {
      content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  .ir a:after {
    content: ""; }
  a[href^="javascript:"]:after, a[href^="#"]:after {
    content: ""; }
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr {
    page-break-inside: avoid; }
  img {
    page-break-inside: avoid;
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; } }

@font-face {
  font-family: 'din-cond-reg';
  src: url("../font/din-regular-webfont.eot");
  src: url("../font/din-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../font/din-regular-webfont.woff") format("woff"), url("../font/din-regular-webfont.ttf") format("truetype"), url("../font/din-regular-webfont.svg#din-cond-reg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'din-cond-black';
  src: url("../font/dincond-black-webfont.eot");
  src: url("../font/dincond-black-webfont.eot?#iefix") format("embedded-opentype"), url("../font/dincond-black-webfont.woff") format("woff"), url("../font/dincond-black-webfont.ttf") format("truetype"), url("../font/dincond-black-webfont.svg#din-cond-black") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'din-black';
  src: url("../font/din-black-webfont.eot");
  src: url("../font/din-black-webfont.eot?#iefix") format("embedded-opentype"), url("../font/din-black-webfont.woff") format("woff"), url("../font/din-black-webfont.ttf") format("truetype"), url("../font/din-black-webfont.svg#din-black") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'mod20';
  src: url("../font/modernno20-webfont.eot");
  src: url("../font/modernno20-webfont.eot?#iefix") format("embedded-opentype"), url("../font/modernno20-webfont.woff") format("woff"), url("../font/modernno20-webfont.ttf") format("truetype"), url("../font/modernno20-webfont.svg#mod20") format("svg");
  font-weight: normal;
  font-style: normal; }

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;
  padding: 0;
  letter-spacing: 2px; }

html {
  width: 100%;
  height: 100%;
  background: #ffffff; }

html,
button,
input,
select,
textarea {
  color: #222; }

html, body {
  height: 100%;
  width: 100%; }

body {
  font-size: 1em;
  line-height: 1.4;
  font-family: "din-cond-reg", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical;
  width: 99%;
  padding: 8px 10px; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

html, body {
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0; }

*, *:before, *:after {
  box-sizing: border-box; }

html {
  background-color: #000; }

h2 {
  font-family: "din-cond-reg", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 3.5rem; }

a {
  color: #6a6057;
  text-decoration: none; }

p {
  font-size: 1.2rem;
  line-height: 1.7; }

wbr {
  display: none; }

span.bold {
  font-weight: bold; }

.container {
  width: 100%;
  border-bottom: 0px solid #fff; }

.innerWrap {
  width: 70%;
  max-width: 1024px;
  margin: 0 auto;
  height: auto; }

section, footer {
  min-height: 700px;
  height: auto;
  border: 20px solid #fff;
  border-bottom: 0px solid transparent;
  overflow-x: hidden;
  overflow-y: visible;
  max-width: 1600px;
  margin: 0 auto; }

footer, section.news {
  border-bottom: 20px solid #fff; }

.mobHam {
  transition: top 0.1s ease-in-out 0s;
  position: fixed;
  display: none;
  z-index: 90;
  top: 20px;
  right: 20px;
  height: 100px;
  width: 100px;
  background: #000;
  cursor: pointer; }
  .mobHam.up {
    top: 0px; }
  .mobHam span {
    transition: background 0.1s ease-in-out 0s;
    display: block;
    height: 12px;
    width: 64px;
    margin-top: 10px;
    background: #fff;
    margin-left: 19px; }
    .mobHam span:first-child {
      margin-top: 21px; }

.mobMenu {
  transition: all 0.29s ease-in-out 0s;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #000;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  font-size: 78px;
  opacity: 0;
  transform: translateX(-100%);
  pointer-events: none; }
  .mobMenu.active {
    transform: translateX(0%);
    opacity: 1;
    pointer-events: auto; }
  .mobMenu ul {
    list-style: none;
    padding: 64px 0 0 55px; }
    .mobMenu ul li {
      margin-bottom: 4px; }
      .mobMenu ul li a {
        transition: color 0.1s ease-in-out 0s;
        text-transform: uppercase;
        font-weight: 800;
        color: #fff; }
  .mobMenu .mobMenu-exit {
    transition: all 0.1s ease-in-out 0s;
    position: absolute;
    height: 64px;
    width: 64px;
    cursor: pointer;
    top: 30px;
    right: 7px;
    padding: 0 67px 50px 50px; }
    .mobMenu .mobMenu-exit.up {
      top: 10px; }
    .mobMenu .mobMenu-exit:hover {
      opacity: 0.75; }
    .mobMenu .mobMenu-exit::after {
      position: absolute;
      transform: rotate(45deg);
      background: #fff;
      content: '';
      height: 78px;
      width: 10px; }
    .mobMenu .mobMenu-exit::before {
      position: absolute;
      transform: rotate(-45deg);
      background: #fff;
      content: '';
      height: 78px;
      width: 10px; }

header {
  width: 100%;
  position: absolute;
  z-index: 9999; }
  header.mediaHeader nav ul.mainNav {
    transform: matrix(1, 0, 0, 1, 0, 0); }
  header.mediaHeader nav .navItem a {
    color: #fff; }
    header.mediaHeader nav .navItem a:hover {
      color: #6cb4bf; }
  header.mediaHeader nav .ham span {
    background: #413c34; }
  header nav {
    position: relative;
    width: 80%; }
    header nav .navMask {
      float: right;
      width: 536px;
      overflow: hidden; }
    header nav ul.mainNav {
      text-align: right;
      text-transform: uppercase;
      font-family: "din-cond-reg", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: bold;
      margin-top: 40px;
      font-size: 1.2rem;
      width: 536px;
      transform: matrix(1, 0, 0, 1, 510, 0); }
    header nav .navItem {
      display: inline-block; }
      header nav .navItem a {
        transition: color 0.1s ease-in-out 0s;
        margin: 0 10px;
        letter-spacing: 0.5px;
        color: #6a6057; }
        header nav .navItem a:hover {
          color: white !important; }
    header nav .ham {
      position: absolute;
      height: 28px;
      width: 28px;
      top: 38px;
      right: 10px;
      padding-bottom: 4px;
      cursor: pointer;
      transform: matrix(1, 0, 0, 1, 56, 0);
      opacity: 0; }
      header nav .ham:hover span {
        background: #fff !important; }
      header nav .ham span {
        transition: background 0.1s ease-in-out 0s;
        display: block;
        height: 4px;
        width: 28px;
        margin-top: 4px;
        background: #6a6057; }

section.hero {
  background-color: #6cb4bf;
  position: relative; }
  section.hero .logo {
    position: relative;
    top: 255px;
    z-index: 10;
    left: 100px; }
  section.hero .heroImg {
    position: absolute;
    top: 130px;
    right: -5%; }
    section.hero .heroImg img {
      transform: scale(0.96); }
    section.hero .heroImg img.animate {
      animation: heroAnim 2s ease-out 0.7s;
      transform: translateZ(0deg);
      animation-fill-mode: forwards; }

@keyframes heroAnim {
  0% {
    transform: scale(0.96); }
  100% {
    transform: scale(1); } }
  section.hero .arrowDown {
    height: 25px;
    width: 25px;
    transform: rotate(135deg);
    position: relative;
    top: 350px;
    left: 115px;
    border-top: 1px solid #6a6057;
    border-right: 1px solid #6a6057;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
    cursor: pointer; }

section.galleryMobile {
  display: none; }

section.gallery {
  background-color: #fff;
  overflow: hidden;
  height: 696px; }
  section.gallery .innerWrap {
    position: relative;
    background-color: #6cb4bf; }
  section.gallery .slideWrap {
    position: absolute;
    left: 50%;
    width: 1240px;
    height: 696px;
    margin-left: -620px; }
  section.gallery .arrow {
    transition: all 0.1s ease-in-out 0s;
    position: absolute;
    top: 50%;
    margin-top: -59px;
    width: 102px;
    height: 102px;
    background-color: transparent;
    border: 18px solid transparent;
    border-top: 18px solid #6a6057;
    opacity: 0.7;
    cursor: pointer; }
    section.gallery .arrow:hover {
      opacity: 0.9; }
    section.gallery .arrow.aRight {
      right: 158px;
      transform: rotate(45deg);
      border-right: 18px solid #6a6057; }
    section.gallery .arrow.aLeft {
      left: 158px;
      transform: rotate(-45deg);
      border-left: 18px solid #6a6057; }
  section.gallery .galleryText {
    transition: all 0.6s ease-in-out 0s;
    position: absolute;
    width: 100%;
    top: 306px;
    text-align: center;
    pointer-events: none;
    opacity: 0; }
    section.gallery .galleryText.shown {
      opacity: 1; }
    section.gallery .galleryText h2 {
      display: inline-block;
      font-family: "din-cond-reg", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 51px;
      letter-spacing: 1px;
      color: #fff;
      padding: 6px 11px;
      line-height: 52px;
      background-color: rgba(0, 0, 0, 0.5);
      background-blend-mode: multiply; }
      section.gallery .galleryText h2 span {
        font-weight: 800; }
  section.gallery ul.slideGroup {
    position: relative;
    width: 2000%;
    height: auto;
    list-style: none;
    margin: 0;
    padding: 0; }
    section.gallery ul.slideGroup li {
      position: absolute;
      top: 0;
      left: 0; }
      section.gallery ul.slideGroup li img {
        width: 1240px;
        height: 696px;
        pointer-events: none; }

section.about {
  background-color: #6a6057;
  color: #fff;
  position: relative; }
  section.about .aboutText {
    position: relative;
    top: 200px;
    text-align: justify;
    margin: 0 auto;
    width: 90%;
    padding-left: 40px; }
    section.about .aboutText:before, section.about .aboutText:after {
      content: "";
      width: 1px;
      height: 80%;
      border-right: 2px solid #86796e;
      position: absolute;
      left: 0px; }
    section.about .aboutText:after {
      right: 0px;
      left: auto;
      top: 0; }
    section.about .aboutText p {
      width: 95%; }
  section.about .truckImg {
    position: absolute;
    top: 50px;
    right: -10%;
    transform: matrix(1, 0, 0, 1, 20, 0); }

section.history {
  position: relative;
  background-color: #666;
  text-align: center;
  color: #fff;
  padding: 140px 0;
  background-image: url(../../img/jpg/history.jpg);
  background-size: cover;
  background-repeat: no-repeat; }
  section.history .historyMobileWrapper {
    display: none; }
  section.history h2 {
    position: relative;
    top: -10px;
    display: inline-block;
    padding: 0 10px;
    transform: translateY(-25%); }
  section.history p {
    text-align: left;
    margin: 30px 0; }
    section.history p:first-of-type {
      margin-top: -20px; }
  section.history .historyMain {
    width: 85.5%;
    border-bottom: 1px solid #888;
    padding-bottom: 40px;
    margin: 0 auto;
    position: relative; }
    section.history .historyMain:before, section.history .historyMain:after {
      content: "";
      width: calc(50% - 90px);
      height: 1px;
      border-top: 1px solid #cccccc;
      position: absolute;
      left: 0%;
      top: 10px; }
    section.history .historyMain:after {
      right: 0%;
      left: auto; }
  section.history .historyBtn {
    transition: all 0.1s ease-in-out 0s;
    color: #6cb4bf;
    font-size: 2rem;
    border: 1px solid #6cb4bf;
    display: inline-block;
    padding: 5px 10px;
    margin-top: 20px;
    cursor: pointer; }
    section.history .historyBtn:hover {
      cursor: pointer;
      background: rgba(255, 255, 255, 0.1); }
    section.history .historyBtn h6 {
      font-size: 2rem;
      font-weight: 600; }

.history-modal-wrapper {
  transition: all 0.19s ease-in-out 0s;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0; }
  .history-modal-wrapper.active {
    pointer-events: auto;
    opacity: 1; }
  .history-modal-wrapper .history-modal {
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    width: 792px;
    height: 446px;
    margin-left: -396px;
    margin-top: -223px;
    background: #fff; }
    .history-modal-wrapper .history-modal p {
      position: absolute;
      left: 25px;
      bottom: 13px;
      margin: 0;
      color: #5e554d;
      font-weight: 800;
      font-size: 16px;
      text-transform: uppercase; }
      .history-modal-wrapper .history-modal p span.white {
        color: #ffffff; }
      .history-modal-wrapper .history-modal p span.brown {
        color: #6a6057; }
    .history-modal-wrapper .history-modal .history-exit {
      transition: all 0.1s ease-in-out 0s;
      position: absolute;
      top: 0;
      right: 0;
      font-family: "din-black", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
      color: #666a66;
      background-color: #000000;
      font-size: 20px;
      line-height: 20px;
      cursor: pointer;
      padding: 4px 9px 6px 10px; }
      .history-modal-wrapper .history-modal .history-exit:hover {
        opacity: 0.9; }
    .history-modal-wrapper .history-modal .history-paging {
      transition: all 0.1s ease-in-out 0s;
      position: absolute;
      top: 50%;
      margin-top: -14px;
      font-family: "din-cond-reg", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 25px;
      color: #fff;
      background-color: #000000;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 800;
      line-height: 25px;
      padding: 2px 4px 1px; }
      .history-modal-wrapper .history-modal .history-paging:hover {
        opacity: 0.8; }
      .history-modal-wrapper .history-modal .history-paging.history-prev {
        left: 25px; }
      .history-modal-wrapper .history-modal .history-paging.history-next {
        right: 25px; }
    .history-modal-wrapper .history-modal ul.history-slider {
      width: 3000%;
      height: 446px;
      list-style: none;
      margin: 0;
      padding: 0; }
      .history-modal-wrapper .history-modal ul.history-slider li {
        float: left;
        height: 446px;
        width: 792px;
        overflow: hidden; }
        .history-modal-wrapper .history-modal ul.history-slider li img {
          height: 100%;
          width: 100%; }
        .history-modal-wrapper .history-modal ul.history-slider li span {
          display: none !important; }

section.team {
  background-color: #6cb4bf;
  color: #fff;
  text-align: center; }
  section.team h2 {
    background-color: #6cb4bf;
    position: relative;
    top: -20px;
    display: inline-block;
    padding: 0 10px;
    transform: translateY(-25%); }
  section.team p {
    color: #6a6057;
    text-align: justify;
    line-height: 2;
    letter-spacing: 0px; }
  section.team .teamMain {
    width: 85.5%;
    margin: 100px auto;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    min-height: 470px; }
  section.team .teamTexts {
    position: relative;
    padding-bottom: 20px; }
    section.team .teamTexts .teamText {
      position: relative;
      top: 20px;
      visibility: hidden; }
      section.team .teamTexts .teamText.active {
        visibility: visible; }
      section.team .teamTexts .teamText span.bold {
        text-transform: uppercase; }
      section.team .teamTexts .teamText a:hover, section.team .teamTexts .teamText a:visited {
        color: inherit; }
    section.team .teamTexts .teamText2 {
      position: absolute; }
  section.team .teamLogos {
    width: 363px;
    margin: 0 auto; }
    section.team .teamLogos .teamLogo {
      background-size: contain !important;
      background-repeat: no-repeat;
      position: absolute;
      opacity: 0;
      transition: opacity 0.3s ease;
      width: 50%; }
      section.team .teamLogos .teamLogo.active {
        opacity: 1; }
    section.team .teamLogos .teamChoice, section.team .teamLogos .teamWitt {
      width: 50%;
      float: left;
      position: relative;
      cursor: pointer; }
    section.team .teamLogos .teamOutline {
      float: left; }
    section.team .teamLogos .teamChoice {
      width: 155px;
      height: 80px;
      left: -20px; }
      section.team .teamLogos .teamChoice:hover .teamLogo1 {
        opacity: 0; }
      section.team .teamLogos .teamChoice:hover .teamLogo1Dk {
        opacity: 1; }
    section.team .teamLogos .teamWitt {
      width: 200px;
      height: 50px;
      right: -40px;
      top: 5px; }
      section.team .teamLogos .teamWitt:hover .teamLogo2 {
        opacity: 0; }
      section.team .teamLogos .teamWitt:hover .teamLogo2Dk {
        opacity: 1; }
    section.team .teamLogos .teamLogo1, section.team .teamLogos .teamLogo1Dk {
      background-image: url(../../img/svg/choiceLogo_lt.svg);
      width: 100%;
      height: 100%; }
      .no-svg section.team .teamLogos .teamLogo1, .no-svg section.team .teamLogos .teamLogo1Dk {
        background-image: url(../../img/png/choiceLogo_lt.png); }
    section.team .teamLogos .teamLogo2, section.team .teamLogos .teamLogo2Dk {
      background-image: url(../../img/svg/wittLogo_lt.svg);
      width: 100%;
      height: 100%; }
      .no-svg section.team .teamLogos .teamLogo2, .no-svg section.team .teamLogos .teamLogo2Dk {
        background-image: url(../../img/png/wittLogo_lt.png); }
    section.team .teamLogos .teamLogo1Dk {
      background-image: url(../../img/svg/choiceLogo_dk.svg); }
      .no-svg section.team .teamLogos .teamLogo1Dk {
        background-image: url(../../img/png/choiceLogo_dk.png); }
    section.team .teamLogos .teamLogo2Dk {
      background-image: url(../../img/svg/wittLogo_dk.svg); }
      .no-svg section.team .teamLogos .teamLogo2Dk {
        background-image: url(../../img/png/wittLogo_dk.png); }

section.news {
  background-color: #6a6057;
  text-align: center;
  color: #fff;
  position: relative;
  overflow-y: visible;
  min-height: 600px; }
  section.news .innerWrap {
    position: relative; }
  section.news .textarrows {
    padding-bottom: 40px;
    margin-top: -150px; }
  section.news .textarrow {
    transition: color 0.1s ease-in-out 0s;
    cursor: pointer;
    font-family: "mod20", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    width: 50%;
    float: left;
    margin: -20px auto 0;
    text-align: center; }
    section.news .textarrow.prev {
      padding-left: 20%; }
    section.news .textarrow.next {
      padding-right: 20%; }
    section.news .textarrow p {
      font-size: 3rem;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.3);
      transition: color 0.2s ease; }
      section.news .textarrow p:hover {
        color: #fff; }
  section.news .sectional {
    padding-bottom: 20px; }
  section.news .articlesGroup {
    position: relative;
    width: 100%;
    top: -100px;
    min-height: 390px; }
  section.news .articlesInner {
    display: none;
    height: 100%; }
    section.news .articlesInner.active {
      display: block; }
  section.news ul {
    margin: 0;
    padding: 0; }
  section.news li {
    list-style: none; }
  section.news a {
    color: #fff; }
  section.news .date {
    position: relative;
    display: inline-block; }
    section.news .date .before, section.news .date .after {
      width: 40px;
      height: 1px;
      border-top: 1px solid white;
      display: block;
      position: absolute;
      top: 50%;
      right: 110%; }
    section.news .date .after {
      left: 110%; }
    section.news .date h5 {
      font-size: 2rem;
      letter-spacing: -1px;
      display: inline-block; }
  section.news .article {
    line-height: 1.6; }
  section.news .articleTitle {
    text-transform: none;
    font-size: 1.3rem;
    letter-spacing: -0.1px; }
    section.news .articleTitle .publication {
      font-family: "din-cond-black", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif; }
  section.news h2 {
    line-height: 1;
    pointer-events: none;
    font-family: "mod20", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15rem;
    opacity: 0.3;
    color: black;
    position: relative;
    top: 200px; }

footer {
  background-color: #6a6057;
  min-height: 300px;
  text-align: center;
  color: #fff;
  border-top: 20px solid #fff;
  border-bottom: 20px solid #fff; }
  footer p {
    margin-bottom: 50px; }
  footer .choiceLink {
    display: block;
    width: 100%;
    height: 100%; }
  footer .teamLogos {
    width: 363px;
    margin: 0 auto; }
    footer .teamLogos .teamLogo {
      width: 50%;
      float: left;
      background-size: contain !important;
      background-repeat: no-repeat; }
    footer .teamLogos .teamLogo1 {
      background-image: url(../../img/svg/choiceLogo_lt.svg);
      width: 155px;
      height: 80px; }
      .no-svg footer .teamLogos .teamLogo1 {
        background-image: url(../../img/png/choiceLogo_lt.png); }
      .no-svg footer .teamLogos .teamLogo1 {
        background-image: url(../../img/png/wittLogo_lt.png); }
    footer .teamLogos .teamLogo2 {
      background-image: url(../../img/svg/wittLogo_lt.svg);
      width: 200px;
      height: 80px;
      position: relative;
      left: 50px; }
      .no-svg footer .teamLogos .teamLogo2 {
        background-image: url(../../img/png/choiceLogo_lt.png); }
      .no-svg footer .teamLogos .teamLogo2 {
        background-image: url(../../img/png/wittLogo_lt.png); }

.contact-modal-wrap {
  transition: all 0.19s ease-in-out 0s;
  position: fixed;
  background-color: transparent;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  z-index: 9999; }
  .contact-modal-wrap.active {
    pointer-events: auto;
    opacity: 1; }
  .contact-modal-wrap .contact-modal {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    width: 440px;
    height: 278px;
    top: 50%;
    left: 50%;
    margin-top: -139px;
    margin-left: -220px;
    text-align: center;
    padding: 40px; }
    .contact-modal-wrap .contact-modal p {
      transition: opacity 0.1s ease-in-out 0s;
      color: #6cb4bf;
      font-size: 23px;
      text-transform: uppercase;
      line-height: 23px;
      margin-top: 65px; }
      .contact-modal-wrap .contact-modal p a {
        margin-top: -55px;
        display: block;
        text-transform: none;
        font-size: 39px;
        font-weight: 800;
        color: #fff; }
        .contact-modal-wrap .contact-modal p a:hover {
          color: #6cb4bf; }
        .contact-modal-wrap .contact-modal p a span {
          font-family: "mod20", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif; }
    .contact-modal-wrap .contact-modal .contact-exit {
      transition: opacity 0.1s ease-in-out 0s;
      position: absolute;
      height: 36px;
      width: 36px;
      cursor: pointer;
      top: 5px;
      right: 9px; }
      .contact-modal-wrap .contact-modal .contact-exit:hover {
        opacity: 0.75; }
      .contact-modal-wrap .contact-modal .contact-exit::after {
        position: absolute;
        transform: rotate(45deg);
        background: #fff;
        content: '';
        height: 40px;
        width: 6px; }
      .contact-modal-wrap .contact-modal .contact-exit::before {
        position: absolute;
        transform: rotate(-45deg);
        background: #fff;
        content: '';
        height: 40px;
        width: 6px; }

.media-modal-wrap {
  transition: all 0.19s ease-in-out 0s;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  z-index: 9999; }
  .media-modal-wrap.active {
    pointer-events: auto;
    opacity: 1; }
  .media-modal-wrap .media-modal {
    position: absolute;
    background: #6cb4bf;
    width: 800px;
    height: 320px;
    top: 50%;
    left: 50%;
    margin-top: -210px;
    margin-left: -400px;
    text-align: center;
    padding: 40px; }
    .media-modal-wrap .media-modal h2 {
      color: #6a6057;
      display: inline-block;
      margin-bottom: 20px; }
      .media-modal-wrap .media-modal h2:before, .media-modal-wrap .media-modal h2:after {
        content: '';
        width: 210px;
        height: 1px;
        border-top: 1px solid #6a6057;
        display: block;
        top: 80px;
        left: 7.5%;
        position: absolute; }
      .media-modal-wrap .media-modal h2:after {
        left: 66%; }
  .media-modal-wrap form {
    width: 95%;
    margin: 0 auto;
    display: block; }
    .media-modal-wrap form span.inEnclose {
      display: block;
      width: 48%;
      text-align: left;
      float: left; }
      .media-modal-wrap form span.inEnclose:nth-child(2) {
        margin-left: 2px;
        float: right; }
    .media-modal-wrap form label {
      color: #fff;
      text-transform: uppercase;
      font-size: 1.4rem; }
    .media-modal-wrap form input {
      transition: all 0.1s ease-in-out 0s;
      border: 0px solid transparent;
      width: 100%;
      height: 40px;
      float: left;
      padding: 0 15px; }
      .media-modal-wrap form input:focus {
        outline: none;
        box-shadow: 0 0 5px #6a6057;
        border: 1px solid #6a6057; }
      .media-modal-wrap form input.submit {
        width: 100px;
        background-color: #6a6057;
        color: #fff;
        font-family: "din-cond-reg", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
        text-transform: uppercase;
        font-size: 1.4rem;
        float: right;
        margin-top: 24px;
        padding: 0; }
        .media-modal-wrap form input.submit:hover {
          color: #6a6057;
          background-color: #fff; }

header.mediaHeader {
  width: 100%;
  max-width: 1600px;
  left: 0;
  right: 0; }
  header.mediaHeader nav {
    width: 70%;
    margin: 0 auto; }
    header.mediaHeader nav ul {
      width: 100%; }
  header.mediaHeader a {
    color: #fff; }
  header.mediaHeader:hover {
    color: #6cb4bf; }

.mediaContainer {
  border: 20px solid #fff;
  border-bottom: none;
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
  background-color: #6a6057;
  color: #fff;
  padding-top: 120px;
  text-transform: uppercase; }
  .mediaContainer .innerWrap {
    max-width: 800px;
    padding-bottom: 50px; }
  .mediaContainer h3 {
    font-family: "din-cond-reg", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 900;
    font-size: 1.5rem;
    letter-spacing: 1px;
    color: #6cb4bf; }
  .mediaContainer .mediaTitle {
    color: #fff;
    text-align: center;
    position: relative; }
    .mediaContainer .mediaTitle .keyline {
      position: absolute;
      width: calc(50% - 80px);
      height: 1px;
      display: block;
      border-top: 1px solid #fff;
      top: 50%; }
      .mediaContainer .mediaTitle .keyline:last-child {
        right: 0; }
    .mediaContainer .mediaTitle h2 {
      display: inline-block; }
  .mediaContainer .section {
    border-bottom: 1px solid #6cb4bf;
    margin-bottom: 25px;
    padding-bottom: 10px; }
    .mediaContainer .section:last-child {
      border-bottom: 2px solid #9e9389; }
    .mediaContainer .section p {
      margin: 15px 0;
      line-height: 1.2; }
  .mediaContainer .quartCont {
    width: 100%;
    margin: 10px 0 20px; }
  .mediaContainer .quart {
    width: calc(25% - 7.5px);
    float: left;
    color: #fff;
    text-align: center;
    margin-right: 10px; }
    .mediaContainer .quart:nth-child(4n) {
      margin-right: 0; }
    .mediaContainer .quart .download {
      margin: 10px 0; }
      .mediaContainer .quart .download span {
        font-weight: 100; }
      .mediaContainer .quart .download p {
        margin: 0; }
        .mediaContainer .quart .download p.downloadLinks {
          font-weight: bold; }
    .mediaContainer .quart .quartImg {
      width: 100%;
      height: 110px;
      background-color: #fff;
      overflow: hidden; }
      .mediaContainer .quart .quartImg img {
        min-width: 193px;
        min-height: 111px;
        max-width: 200px;
        max-height: 120px; }
  .mediaContainer a {
    color: #fff; }

@media only screen and (max-width: 898px) {
  section.gallery .arrow.aLeft {
    left: 440px; }
  section.gallery .arrow.aRight {
    right: 440px; }
  .galleryText {
    top: 100px !important; }
  .history-prev.history-paging {
    left: 40px !important; }
  .history-next.history-paging {
    right: 40px !important; }
  .history-modal-wrapper .history-modal p {
    left: 40px; }
  section.hero .logo {
    top: 130px;
    left: 20px; }
  section.hero .heroImg {
    right: -46%;
    top: 200px; }
  section.hero .arrowDown {
    height: 30px;
    width: 30px;
    border-width: 2px;
    left: 21%;
    top: 80%;
    position: absolute;
    transform: translateX(-50%) rotate(135deg); } }

@media only screen and (max-width: 640px) {
  .desktop, nav {
    display: none; }
  .mobHam {
    display: block !important; }
  body, html {
    max-width: 100%;
    font-size: 130%;
    line-height: 1; }
  .innerWrap {
    width: 85%; }
  wbr {
    display: block; }
  .ham span {
    background: #fff; }
  section {
    min-height: 90vh;
    max-height: 1000px; }
  .media-modal-wrap {
    display: none; }
  section.gallery.desktop .slideWrap {
    display: none; }
  .history-modal-wrapper .history-modal {
    display: none; }
  section.hero .logo {
    top: 130px;
    left: 20px; }
  section.hero .heroImg {
    right: -50%;
    top: 320px; }
  section.hero .arrowDown {
    height: 100px;
    width: 100px;
    border-width: 2px;
    left: 50%;
    top: 75%;
    position: absolute;
    transform: translateX(-50%) rotate(135deg); }
  section.galleryMobile {
    display: block;
    position: relative;
    background-color: black;
    height: 900px; }
    section.galleryMobile .innerWrap {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: black;
      top: 0; }
      section.galleryMobile .innerWrap .slideWrap ul {
        margin: 0;
        padding: 0;
        width: 5000%;
        position: relative;
        left: -20px; }
      section.galleryMobile .innerWrap .slideWrap li {
        list-style: none;
        text-align: left;
        float: left;
        top: 0;
        width: 640px;
        transition: left 0.3s ease; }
        section.galleryMobile .innerWrap .slideWrap li img {
          width: 640px; }
        section.galleryMobile .innerWrap .slideWrap li span {
          color: #6cb4bf;
          width: 80%;
          margin: 50px auto 30px;
          display: block;
          font-size: 3.4rem;
          text-transform: capitalize;
          min-height: 140px; }
      section.galleryMobile .innerWrap .galleryCtrls {
        width: 100%;
        position: relative;
        top: 90px;
        text-transform: uppercase; }
        section.galleryMobile .innerWrap .galleryCtrls .arrow {
          width: 50%;
          float: left;
          font-size: 3.7rem;
          display: block;
          width: 100px;
          height: 100px;
          position: absolute;
          top: -30px;
          border: 4px solid #9e9389;
          transform: rotate(45deg);
          cursor: pointer;
          z-index: 5; }
          section.galleryMobile .innerWrap .galleryCtrls .arrow.aLeft {
            left: 110px;
            border-top: transparent;
            border-right: transparent; }
          section.galleryMobile .innerWrap .galleryCtrls .arrow.aRight {
            right: 110px;
            border-bottom: transparent;
            border-left: transparent; }
        section.galleryMobile .innerWrap .galleryCtrls .desc {
          position: relative;
          text-align: center;
          top: -30px;
          font-size: 3.7rem;
          padding: 15px 20px;
          color: #9e9389; }
  section.history {
    position: relative;
    min-height: 1250px; }
    section.history .history-modal-wrapper {
      display: none; }
    section.history .historyBtn {
      padding: 20px; }
    section.history .historyMain:before, section.history .historyMain:after {
      width: calc(45% - 90px); }
    section.history .history-description-container {
      min-height: 270px; }
    section.history .history-description {
      color: #6cb4bf;
      width: 90%;
      margin: 0 auto 30px;
      display: block;
      font-size: 2.5rem;
      text-transform: uppercase;
      padding-top: 100px;
      line-height: 1; }
    section.history .historyMobileWrapper {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: black;
      top: 0;
      transition: all 0.19s ease-in-out 0s; }
      section.history .historyMobileWrapper.active {
        display: block; }
      section.history .historyMobileWrapper .historyMobile ul {
        margin: 0;
        padding: 0;
        width: 10000%;
        height: 387px;
        position: relative;
        top: -50px; }
      section.history .historyMobileWrapper .historyMobile li {
        list-style: none;
        text-align: left;
        float: left;
        position: absolute;
        top: 100px;
        left: 0;
        height: 387px;
        width: 600px;
        overflow: hidden; }
        section.history .historyMobileWrapper .historyMobile li:nth-child(2) {
          left: 600px; }
        section.history .historyMobileWrapper .historyMobile li:nth-child(3) {
          left: 1200px; }
        section.history .historyMobileWrapper .historyMobile li:nth-child(4) {
          left: 1800px; }
        section.history .historyMobileWrapper .historyMobile li:nth-child(5) {
          left: 2400px; }
        section.history .historyMobileWrapper .historyMobile li:nth-child(6) {
          left: 3000px; }
        section.history .historyMobileWrapper .historyMobile li:nth-child(7) {
          left: 3600px; }
        section.history .historyMobileWrapper .historyMobile li:nth-child(8) {
          left: 4200px; }
        section.history .historyMobileWrapper .historyMobile li:nth-child(9) {
          left: 4800px; }
        section.history .historyMobileWrapper .historyMobile li:nth-child(10) {
          left: 5400px; }
        section.history .historyMobileWrapper .historyMobile li span {
          display: none !important; }
      section.history .historyMobileWrapper .galleryCtrls {
        width: 100%;
        position: relative;
        top: 100px;
        text-transform: uppercase; }
        section.history .historyMobileWrapper .galleryCtrls .history-paging {
          width: 50%;
          float: left;
          font-size: 3.7rem;
          cursor: pointer; }
        section.history .historyMobileWrapper .galleryCtrls .history-exit {
          position: relative;
          top: 100px;
          font-size: 3rem;
          padding: 15px 20px;
          border: 1px solid #6cb4bf;
          display: inline-block;
          color: #6cb4bf;
          cursor: pointer; }
  section.about {
    min-height: 90vh; }
    section.about .aboutText {
      width: 90%;
      border-top: 2px solid #9e9389;
      border-bottom: 2px solid #9e9389;
      padding: 0;
      top: 100px; }
      section.about .aboutText:before, section.about .aboutText:after {
        display: none; }
      section.about .aboutText p {
        width: 100%; }
    section.about .truckImg {
      top: auto;
      bottom: 0;
      left: 10%; }
      section.about .truckImg img {
        width: 100vw; }
  section.team {
    min-height: 1100px; }
    section.team .teamMain {
      border-bottom: none; }
    section.team .teamLogos {
      margin: 0;
      width: 100%; }
      section.team .teamLogos .teamChoice, section.team .teamLogos .teamWitt {
        width: 90%; }
      section.team .teamLogos .teamChoice {
        width: 70%;
        top: 15px;
        left: 25px; }
      section.team .teamLogos .teamWitt {
        top: 16px;
        left: 10px; }
    section.team .teamOutline {
      border: 1px solid #fff;
      width: 45%;
      height: 90px;
      float: left;
      padding: 10px;
      position: static; }
      section.team .teamOutline:first-child {
        margin-right: 3%; }
      section.team .teamOutline:last-child {
        margin-left: 3%; }
    section.team .teamText p {
      line-height: 1.4; }
  section.news .textarrows {
    margin-top: -490px; }
    section.news .textarrows .textarrow.prev, section.news .textarrows .textarrow.next {
      padding: 0; }
    section.news .textarrows .textarrow p {
      color: #9e9389; }
  section.news .date .before, section.news .date .after {
    width: 110px; }
  section.news .date h5 {
    font-size: 3rem; }
  section.news .articlesGroup {
    top: -450px; }
    section.news .articlesGroup .article {
      margin-bottom: 20px; }
      section.news .articlesGroup .article:first-child {
        margin-top: 40px; }
      section.news .articlesGroup .article:last-child {
        margin-bottom: 40px; } }

/* @media print,
 * (-o-min-device-pixel-ratio: 5/4),
 * (-webkit-min-device-pixel-ratio: 1.25),
 * (min-resolution: 120dpi)
 *     Style adjustments for high resolution devices */
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  /* IE 6/7 fallback */
  text-indent: -9999px; }

.ir:before {
  content: "";
  display: block;
  width: 0;
  height: 150%; }

.hidden {
  display: none !important;
  visibility: hidden; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.clearfix {
  zoom: 1; }
