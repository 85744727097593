/*
 * HTML5 ✰ Boilerplate
 *
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 *
 * Detailed information about this CSS: h5bp.com/css
 *
 * ==|== normalize ==========================================================
 */

/* =============================================================================
 * HTML5 display definitions
 * ========================================================================== */

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section
  display: block

audio, canvas, video
  display: inline-block
  *display: inline
  *zoom: 1

audio:not([controls]), [hidden]
  display: none

/* =============================================================================
 * Base
 * ========================================================================== */

/*
 * 1. Correct text resizing oddly in IE6/7 when body font-size is set using em units
 * 2. Force vertical scrollbar in non-IE
 * 3. Prevent iOS text size adjust on device orientation change, without disabling user zoom: h5bp.com/g
 */

html
  font-size: 100%
  overflow-y: scroll
  -webkit-text-size-adjust: 100%
  -ms-text-size-adjust: 100%

body
  margin: 0
  font-size: 13px
  line-height: 1.231
  font-family: sans-serif
  color: #222

button, input, select, textarea
  font-family: sans-serif
  color: #222

/*
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection declarations have to be separate
 * Also: hot pink! (or customize the background color to match your design)
 */

::-moz-selection
  background: #fe57a1
  color: #fff
  text-shadow: none
::selection
  background: #fe57a1
  color: #fff
  text-shadow: none

/* =============================================================================
 * Links
 * ========================================================================== */

a
  color: #00e
  &:visited
    color: #551a8b
  &:hover
    color: #06e
  &:focus
    outline: thin dotted
  &:hover, &:active
    outline: 0 // Improve readability when focused and hovered in all browsers: h5bp.com/h

/* =============================================================================
 * Typography
 * ========================================================================== */

abbr[title]
  border-bottom: 1px dotted

b, strong
  font-weight: bold

blockquote
  margin: 1em 40px

dfn
  font-style: italic

hr
  display: block
  height: 1px
  border: 0
  border-top: 1px solid #ccc
  margin: 1em 0
  padding: 0

ins
  background: #ff9
  color: #000
  text-decoration: none

mark
  background: #ff0
  color: #000
  font-style: italic
  font-weight: bold

// Redeclare monospace font family: h5bp.com/j
pre, code, kbd, samp
  font-family: monospace, serif
  _font-family: 'courier new', monospace
  font-size: 1em

// Improve readability of pre-formatted text in all browsers
pre
  white-space: pre
  white-space: pre-wrap
  word-wrap: break-word

q
  quotes: none
  &:before, &:after
    content: ""
    content: none

small
  font-size: 85%

// Position subscript and superscript content without affecting line-height: h5bp.com/k
sub
  font-size: 75%
  line-height: 0
  position: relative
  vertical-align: baseline

sup
  font-size: 75%
  line-height: 0
  position: relative
  vertical-align: baseline
  top: -0.5em

sub
  bottom: -0.25em

/* =============================================================================
 * Lists
 * ========================================================================== */

ul, ol
  margin: 1em 0
  padding: 0 0 0 40px

dd
  margin: 0 0 0 40px

nav
  ul, ol
    list-style: none
    list-style-image: none
    margin: 0
    padding: 0

/* =============================================================================
 * Embedded content
 * ========================================================================== */

/*
 * 1. Improve image quality when scaled in IE7: h5bp.com/d
 * 2. Remove the gap between images and borders on image containers: h5bp.com/e
 */

img
  border: 0
  -ms-interpolation-mode: bicubic
  vertical-align: middle

/*
 * Correct overflow not hidden in IE9
 */

svg:not(:root)
  overflow: hidden

/* =============================================================================
 * Figures
 * ========================================================================== */

figure
  margin: 0

/* =============================================================================
 * Forms
 * ========================================================================== */

form
  margin: 0
fieldset
  border: 0
  margin: 0
  padding: 0

// Indicate that 'label' will shift focus to the associated form element
label
  cursor: pointer

/*
 * 1. Correct color not inheriting in IE6/7/8/9
 * 2. Correct alignment displayed oddly in IE6/7
 */

legend
  border: 0
  *margin-left: -7px
  padding: 0

/*
 * 1. Correct font-size not inheriting in all browsers
 * 2. Remove margins in FF3/4 S5 Chrome
 * 3. Define consistent vertical alignment display in all browsers
 */

button, input, select, textarea
  font-size: 100%
  margin: 0
  vertical-align: baseline
  *vertical-align: middle

/*
 * 1. Define line-height as normal to match FF3/4 (set using !important in the UA stylesheet)
 */

button, input
  line-height: normal

/*
 * 1. Display hand cursor for clickable form elements
 * 2. Allow styling of clickable form elements in iOS
 * 3. Correct inner spacing displayed oddly in IE7 (doesn't effect IE6)
 * 4. Consistent box sizing and appearance
 */

button
  cursor: pointer
  -webkit-appearance: button
  *overflow: visible

input
  &[type="button"], &[type="reset"], &[type="submit"]
    cursor: pointer
    -webkit-appearance: button
    *overflow: visible
  &[type="checkbox"], &[type="radio"]
    box-sizing: border-box
    padding: 0
  &[type="search"]
    -webkit-appearance: textfield
    -moz-box-sizing: content-box
    -webkit-box-sizing: content-box
    box-sizing: content-box
    &::-webkit-search-decoration
      -webkit-appearance: none

/*
 * Remove inner padding and border in FF3/4: h5bp.com/l
 */

button::-moz-focus-inner, input::-moz-focus-inner
  border: 0
  padding: 0

/*
 * 1. Remove default vertical scrollbar in IE6/7/8/9
 * 2. Allow only vertical resizing
 */

textarea
  overflow: auto
  vertical-align: top
  resize: vertical

// Colors for form validity
// input:valid, textarea:valid
  
input:invalid, textarea:invalid
  background-color: #f0dddd

/* =============================================================================
 * Tables
 * ========================================================================== */

table
  border-collapse: collapse
  border-spacing: 0

td
  vertical-align: top

/* ==|== primary styles =====================================================
 * Author:
 * ========================================================================== */

/* ==|== media queries ======================================================
 * PLACEHOLDER Media Queries for Responsive Design.
 * These override the primary ('mobile first') styles
 * Modify as content requires.
 * ========================================================================== */

@media only screen and (min-width: 480px)
  // Style adjustments for viewports 480px and over go here

@media only screen and (min-width: 768px)
  // Style adjustments for viewports 768px and over go here

/* ==|== non-semantic helper classes ========================================
 * Please define your styles before this section.
 * ========================================================================== */

// For image replacement
.ir
  display: block
  border: 0
  text-indent: -999em
  overflow: hidden
  background-color: transparent
  background-repeat: no-repeat
  text-align: left
  direction: ltr
  br
    display: none

// Hide from both screenreaders and browsers: h5bp.com/u
.hidden
  display: none !important
  visibility: hidden

// Hide only visually, but have it available for screenreaders: h5bp.com/v
// Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p
.visuallyhidden
  border: 0
  clip: rect(0 0 0 0)
  height: 1px
  margin: -1px
  overflow: hidden
  padding: 0
  position: absolute
  width: 1px
  &.focusable
    &:active, &:focus
      clip: auto
      height: auto
      margin: 0
      overflow: visible
      position: static
      width: auto

// Hide visually and from screenreaders, but maintain layout
.invisible
  visibility: hidden

// Contain floats: h5bp.com/q
.clearfix
  &:before
    content: ""
    display: table
  &:after
    content: ""
    display: table
    clear: both
  *zoom: 1

/* ==|== print styles =======================================================
 * Print styles.
 * Inlined to avoid required HTTP connection: h5bp.com/r
 * ========================================================================== */

@media print
  // Black prints faster: h5bp.com/s
  *
    background: transparent !important
    color: black !important
    box-shadow: none !important
    text-shadow: none !important
    filter: none !important
    -ms-filter: none !important
  a
    text-decoration: underline
    &:visited
      text-decoration: underline
    &[href]:after
      content: " (" attr(href) ")"
  abbr[title]:after
    content: " (" attr(title) ")"

  // Don't show links for images, or javascript/internal links
  .ir a:after
    content: ""
  a
    &[href^="javascript:"]:after, &[href^="#"]:after
      content: ""
  pre, blockquote
    border: 1px solid #999
    page-break-inside: avoid

  // h5bp.com/t
  thead
    display: table-header-group
  tr
    page-break-inside: avoid
  img
    page-break-inside: avoid
    max-width: 100% !important
  @page
    margin: 0.5cm
  p, h2, h3
    orphans: 3
    widows: 3
  h2, h3
    page-break-after: avoid