@media only screen and (max-width: 898px)
    section.gallery .arrow.aLeft
        left: 440px
    section.gallery .arrow.aRight
        right: 440px
    .galleryText
        top: 100px !important
    .history-prev.history-paging
        left: 40px !important
    .history-next.history-paging
        right: 40px !important
    .history-modal-wrapper .history-modal p
        left: 40px
    section.hero
        .logo
            top: 130px
            left: 20px
        .heroImg
            right: -46%
            top: 200px
        .arrowDown
            height: 30px
            width: 30px
            border-width: 2px
            left: 21%
            top: 80%
            position: absolute
            // top: auto
            transform: translateX(-50%) rotate(135deg)


@media only screen and (max-width: 640px)

    .desktop, nav
        display: none

    .mobHam
        display: block !important

    body, html
        // width: 640px
        max-width: 100%
        font-size: 130%
        line-height: 1

    .innerWrap 
        width: 85%

    wbr
        display: block

    .ham
        span
            background: $white

    section
        min-height: 90vh
        max-height: 1000px

    .media-modal-wrap
        display: none

    section.gallery.desktop .slideWrap
        display: none

    .history-modal-wrapper .history-modal
        display: none

    // ------------------------
    // SECTIONS
    // ------------------------
    
    section.hero
        .logo
            top: 130px
            left: 20px
        .heroImg
            right: -50%
            top: 320px
        .arrowDown
            height: 100px
            width: 100px
            border-width: 2px
            left: 50%
            top: 75%
            position: absolute
            // top: auto
            transform: translateX(-50%) rotate(135deg) 

    section.galleryMobile
        display: block
        position: relative
        background-color: black
        height: 900px
        .innerWrap
            display: block
            position: absolute
            width: 100%
            height: 100%
            background-color: black
            top: 0
            .slideWrap
                ul
                    margin: 0
                    padding: 0
                    width: 5000%
                    position: relative
                    left: -20px

                li
                    list-style: none
                    text-align: left
                    // position: absolute
                    // display: none
                    float: left
                    top: 0 
                    width: 640px
                    transition: left 0.3s ease
                    img
                        width: 640px
                    span
                        color: $teal
                        width: 80%
                        margin: 50px auto 30px
                        display: block
                        font-size: 3.4rem
                        text-transform: capitalize
                        min-height: 140px
            .galleryCtrls
                width: 100%
                position: relative
                top: 90px
                text-transform: uppercase
                .arrow
                    width: 50%
                    float: left
                    font-size: 3.7rem
                    display: block
                    width: 100px
                    height: 100px
                    position: absolute
                    top: -30px
                    border: 4px solid lighten($brown, 20%)
                    transform: rotate(45deg)
                    cursor: pointer
                    z-index: 5
                    &.aLeft
                        left: 110px
                        border-top: transparent
                        border-right: transparent
                    &.aRight
                        right: 110px
                        border-bottom: transparent
                        border-left: transparent

                .desc
                    position: relative
                    text-align: center
                    top: -30px
                    font-size: 3.7rem
                    padding: 15px 20px
                    color: lighten($brown, 20%)


    section.history
        position: relative
        min-height: 1250px
        .history-modal-wrapper
            display: none
        .historyBtn
            padding: 20px
        .historyMain
            &:before, &:after
                width: calc(45% - 90px)
        .history-description-container
            min-height: 270px
        .history-description
            color: $teal
            width: 90%
            margin: 0 auto 30px
            display: block
            font-size: 2.5rem
            text-transform: uppercase
            padding-top: 100px
            line-height: 1
        .historyMobileWrapper
            display: none
            position: absolute
            width: 100%
            height: 100%
            background-color: black
            top: 0
            transition: all 0.19s ease-in-out 0s
            &.active
                display: block
            .historyMobile
                ul
                    margin: 0
                    padding: 0
                    width: 10000%
                    height: 387px
                    position: relative
                    top: -50px
                li
                    list-style: none
                    text-align: left
                    float: left
                    position: absolute
                    top: 100px
                    left: 0
                    height: 387px
                    width: 600px 
                    overflow: hidden
                    &:nth-child(2)
                        left: 600px
                    &:nth-child(3)
                        left: 1200px
                    &:nth-child(4)
                        left: 1800px
                    &:nth-child(5)
                        left: 2400px
                    &:nth-child(6)
                        left: 3000px
                    &:nth-child(7)
                        left: 3600px
                    &:nth-child(8)
                        left: 4200px
                    &:nth-child(9)
                        left: 4800px
                    &:nth-child(10)
                        left: 5400px
                    span
                        display: none !important
            .galleryCtrls
                width: 100%
                position: relative
                top: 100px
                text-transform: uppercase
                .history-paging
                    width: 50%
                    float: left
                    font-size: 3.7rem
                    cursor: pointer
                .history-exit
                    position: relative
                    top: 100px
                    font-size: 3rem
                    padding: 15px 20px
                    border: 1px solid $teal
                    display: inline-block
                    color: $teal
                    cursor: pointer
    section.about
        min-height: 90vh
        .aboutText
            width: 90%
            border-top: 2px solid lighten($brown, 20%)
            border-bottom: 2px solid lighten($brown, 20%)
            padding: 0
            top: 100px
            &:before, &:after
                display: none
            p
                width: 100%
        .truckImg
            top: auto
            bottom: 0
            left: 10%
            img
                width: 100vw

    section.team
        min-height: 1100px
        .teamMain
            border-bottom: none
        .teamLogos
            margin: 0
            width: 100%
            .teamChoice, .teamWitt
                width: 90%
            .teamChoice
                width: 70%
                top: 15px
                left: 25px
            .teamWitt
                top: 16px
                left: 10px
        .teamOutline
            border: 1px solid $white
            width: 45%
            height: 90px
            float: left
            padding: 10px
            position: static
            &:first-child
                margin-right: 3%
            &:last-child
                margin-left: 3%
        .teamText p
            line-height: 1.4

    section.news
        .textarrows
            margin-top: -490px
            .textarrow
                &.prev, &.next
                    padding: 0
                p
                    color: lighten($brown, 20%)
        .date
            .before, .after
                width: 110px
            h5
                font-size: 3rem
        .articlesGroup
            top: -450px
            .article
                margin-bottom: 20px
                &:first-child
                    margin-top: 40px
                &:last-child
                    margin-bottom: 40px

/* @media print,
 * (-o-min-device-pixel-ratio: 5/4),
 * (-webkit-min-device-pixel-ratio: 1.25),
 * (min-resolution: 120dpi)
 *     Style adjustments for high resolution devices */

@media print
    * 
        background: transparent !important
        color: #000 !important /* Black prints faster: h5bp.com/s */
        box-shadow: none !important
        text-shadow: none !important
    a,
    a:visited 
        text-decoration: underline
    

    a[href]:after
        content: " (" attr(href) ")"
    

    abbr[title]:after
        content: " (" attr(title) ")"
    
    .ir a:after,
    a[href^="javascript:"]:after,
    a[href^="#"]:after
        content: ""

    pre,
    blockquote 
        border: 1px solid #999
        page-break-inside: avoid

    thead 
        display: table-header-group /* h5bp.com/t */

    tr,
    img
        page-break-inside: avoid

    img
        max-width: 100% !important

    @page 
        margin: 0.5cm

    p,
    h2,
    h3
        orphans: 3
        widows: 3

    h2,
    h3
        page-break-after: avoid