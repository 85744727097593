@font-face
	font-family: 'din-cond-reg'
	src: url('../font/din-regular-webfont.eot')
	src: url('../font/din-regular-webfont.eot?#iefix') format('embedded-opentype'), url('../font/din-regular-webfont.woff') format('woff'), url('../font/din-regular-webfont.ttf') format('truetype'), url('../font/din-regular-webfont.svg#din-cond-reg') format('svg')
	font-weight: normal
	font-style: normal

@font-face
	font-family: 'din-cond-black'
	src: url('../font/dincond-black-webfont.eot')
	src: url('../font/dincond-black-webfont.eot?#iefix') format('embedded-opentype'), url('../font/dincond-black-webfont.woff') format('woff'), url('../font/dincond-black-webfont.ttf') format('truetype'), url('../font/dincond-black-webfont.svg#din-cond-black') format('svg')
	font-weight: normal
	font-style: normal

@font-face
	font-family: 'din-black'
	src: url('../font/din-black-webfont.eot')
	src: url('../font/din-black-webfont.eot?#iefix') format('embedded-opentype'), url('../font/din-black-webfont.woff') format('woff'), url('../font/din-black-webfont.ttf') format('truetype'), url('../font/din-black-webfont.svg#din-black') format('svg')
	font-weight: normal
	font-style: normal

@font-face
	font-family: 'mod20'
	src: url('../font/modernno20-webfont.eot')
	src: url('../font/modernno20-webfont.eot?#iefix') format('embedded-opentype'), url('../font/modernno20-webfont.woff') format('woff'), url('../font/modernno20-webfont.ttf') format('truetype'), url('../font/modernno20-webfont.svg#mod20') format('svg')
	font-weight: normal
	font-style: normal

