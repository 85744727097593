//-- colors

@import "_normalize.sass"
@import "_fontface.sass"
@import "_varmix.sass"
@import "_typography.sass"


html
    width: 100%
    height: 100%
    background: #ffffff

html,
button,
input,
select,
textarea
    color: #222

html, body
    height: 100%
    width: 100%

body
    font-size: 1em
    line-height: 1.4
    font-family: $regular

::-moz-selection
    background: #b3d4fc
    text-shadow: none


::selection
    background: #b3d4fc
    text-shadow: none

hr
    display: block
    height: 1px
    border: 0
    border-top: 1px solid #ccc
    margin: 1em 0
    padding: 0

img
    vertical-align: middle

fieldset
    border: 0
    margin: 0
    padding: 0

textarea
    resize: vertical
    width: 99%
    padding: 8px 10px

.chromeframe
    margin: 0.2em 0
    background: #ccc
    color: #000
    padding: 0.2em 0


@import "_author.sass"
@import "_mediaquery.sass"

.ir
    background-color: transparent
    border: 0
    overflow: hidden
    /* IE 6/7 fallback */
    text-indent: -9999px


.ir:before
    content: ""
    display: block
    width: 0
    height: 150%

.hidden
    display: none !important
    visibility: hidden

.visuallyhidden
    border: 0
    clip: rect(0 0 0 0)
    height: 1px
    margin: -1px
    overflow: hidden
    padding: 0
    position: absolute
    width: 1px

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus
    clip: auto
    height: auto
    margin: 0
    overflow: visible
    position: static
    width: auto

.invisible
    visibility: hidden

.clearfix:before,
.clearfix:after
    content: " "
    display: table


.clearfix:after
    clear: both

.clearfix
    zoom: 1


